import * as _preact3 from "preact";

var _preact2 = "default" in _preact3 ? _preact3.default : _preact3;

import * as _core3 from "@uppy/core";

var _core2 = "default" in _core3 ? _core3.default : _core3;

var exports = {};
var _preact = _preact2;
var _core = _core2;
const packageJson = {
  "version": "2.1.1"
};
/**
 * Progress bar
 *
 */

class ProgressBar extends _core.UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || "ProgressBar";
    this.title = "Progress Bar";
    this.type = "progressindicator"; // set default options

    const defaultOptions = {
      target: "body",
      fixed: false,
      hideAfterFinish: true
    }; // merge default options with the ones set by user

    this.opts = { ...defaultOptions,
      ...opts
    };
    this.render = this.render.bind(this);
  }

  render(state) {
    const progress = state.totalProgress || 0; // before starting and after finish should be hidden if specified in the options

    const isHidden = (progress === 0 || progress === 100) && this.opts.hideAfterFinish;
    return (0, _preact.h)("div", {
      className: "uppy uppy-ProgressBar",
      style: {
        position: this.opts.fixed ? "fixed" : "initial"
      },
      "aria-hidden": isHidden
    }, (0, _preact.h)("div", {
      className: "uppy-ProgressBar-inner",
      style: {
        width: `${progress}%`
      }
    }), (0, _preact.h)("div", {
      className: "uppy-ProgressBar-percentage"
    }, progress));
  }

  install() {
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

  uninstall() {
    this.unmount();
  }

}

ProgressBar.VERSION = packageJson.version;
exports = ProgressBar;
export default exports;